import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const assureApi = createApi({
  reducerPath: "assure",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set("Authorization", `Token ${localStorage.getItem("token")}`);
      return headers;
    },
  }),
  tagTypes: ["assure"],
  endpoints: (builder) => ({
    getAssureList: builder.query<any, void>({
      query: () => ({
        url: "/assurance-details-list/",
        method: "GET",
      }),
      providesTags: ["assure"],
    }),
    requestAssure: builder.mutation<any, object>({
      query: (assureInfo: object) => ({
        url: "/assure-request/",
        method: "POST",
        body: assureInfo,
      }),
      invalidatesTags: ["assure"],
    }),
  }),
});

export const { useGetAssureListQuery,useRequestAssureMutation } = assureApi;
