export const config = () => {
  let LOGIN_API: string;
  let emailValidator: any;
  let BASE_URL: string;

  
    BASE_URL = process.env.REACT_APP_API_URL;

  return {
    LOGIN_API,
    BASE_URL,
    emailValidator,
  };
};
