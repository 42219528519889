import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const galleryApi = createApi({
    reducerPath: "gallery",
    baseQuery: fetchBaseQuery({
         baseUrl:config().BASE_URL,
         prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem("token")}`);
            return headers
        }
        }),
    tagTypes: ['gallery'],
    endpoints: (builder) => ({
        getListOfGallery:builder.query<any,void>({
            query:()=>({
                url: "/my-gallery/?customer=1/",
                method: "GET",
            }),
            providesTags: ['gallery']
        }),
    }),
});

export const {useGetListOfGalleryQuery} = galleryApi;
