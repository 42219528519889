import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const testimonialApi = createApi({
    reducerPath: "testimonial",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem("token")}`);
            return headers
        }
    }),
    tagTypes: ['testimonial'],
    endpoints: (builder) => ({
        GetTestimonialList: builder.query<any, void>({
            query: () => ({
                url: "/review/?review_type=testimonial",
                method: "GET",
            }),
            providesTags: ['testimonial']
        }),
        addTestimonial: builder.mutation<any,any>({
            query: (testimonialInfo) => ({
                url: "/review/?review_type=121/",
                method: "POST",
                body:testimonialInfo,
            }),
            invalidatesTags: ['testimonial']
        }),
        
    }),
});

export const {  useGetTestimonialListQuery,useAddTestimonialMutation} = testimonialApi;
