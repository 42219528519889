import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InitialState = {
  headerNavigatorValue: string;
  isSosContactEdit: boolean;
  isSosContactEditValue: any;
  eventJoinData: any;
};
const initialState: InitialState = {
  headerNavigatorValue: "",
  isSosContactEdit: false,
  isSosContactEditValue: {},
  eventJoinData: {},
};
const headerHighlightSlice = createSlice({
  name: "HeaderNaviagtorValue",
  initialState,
  reducers: {
    setHeaderNavigatorValue: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.headerNavigatorValue = action.payload;
    },
    setIsSosContactEdit: (
      state: InitialState,
      action: PayloadAction<boolean>
    ) => {
      state.isSosContactEdit = action.payload;
    },
    setIsSosContactEditValue: (
      state: InitialState,
      action: PayloadAction<any>
    ) => {
      state.isSosContactEditValue = action.payload;
    },
    setEventJoinData: (state: InitialState, action: PayloadAction<any>) => {
      state.eventJoinData = action.payload;
    },
  },
});
export default headerHighlightSlice.reducer;
export const {
  setHeaderNavigatorValue,
  setIsSosContactEdit,
  setIsSosContactEditValue,
} = headerHighlightSlice.actions;
