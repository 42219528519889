import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const loginApi = createApi({
    reducerPath: "loginApi",
    baseQuery: fetchBaseQuery({ baseUrl: `${config().BASE_URL}`}),
    tagTypes: ['login'],
    endpoints: (builder) => ({
        Login: builder.mutation<any, any>({
            query: (loginInfo) => ({
                url: "/login/",
                method: "POST",
                body: loginInfo,
            }),
        }),
        deactivate: builder.mutation<any, any>({
            query: (data) => ({
                url: `/customers/delete_request/`,
                method: "DELETE",
                body: data,
            }),
        }),
        
    }),
});

export const { useLoginMutation ,useDeactivateMutation } = loginApi;
