import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const ReferralApi = createApi({
  reducerPath: "referral",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set("Authorization", `Token ${localStorage.getItem("token")}`);
      return headers;
    },
  }),
  tagTypes: ["/staff-referral/"],
  endpoints: (builder) => ({
    getReferralList: builder.query<any, void>({
      query: () => ({
        url: "/staff-referral/",
        method: "GET",
      }),
      providesTags: ["/staff-referral/"],
    }),
    AddReferral: builder.mutation<any, object>({
      query: (referralInfo: object) => ({
        url: "/staff-referral/",
        method: "POST",
        body: referralInfo,
      }),
      invalidatesTags: ["/staff-referral/"],
    }),
  }),
});

export const { useAddReferralMutation , useGetReferralListQuery } = ReferralApi;
