import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const visitorApi = createApi({
    reducerPath: "visitorApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem("token")}`);
            return headers
        }
    }),
    tagTypes: ['visitor'],
    endpoints: (builder) => ({
        getVisitorList: builder.query<any, {pages:any}>({
            query: ({pages}) => ({
                url: "/visitors/",
                method: "GET",
                params:{
                    page:pages
                }
            }),
            providesTags: ['visitor']
        }),

        addVisitor: builder.mutation<any, any>({
            query: (supportInfo) => ({
                url: "/visitors/",
                method: "POST",
                body: supportInfo,
            }),
            invalidatesTags: ['visitor']
        }),
        deleteVisitor: builder.mutation<any, any>({
            query: (id) => ({
                url: `/visitors/${id}/`,
                method: "DELETE",
            }),
            invalidatesTags: ['visitor']
        }),
        updateVisitor: builder.mutation<any, any>({
            query: (editInfo:any) => ({
                url: `/visitors/${editInfo.id}/`,
                method: "PATCH",
                body:editInfo.visitorData,
            }),
            invalidatesTags: ['visitor']
        }),
    }),
});

export const { useAddVisitorMutation, useGetVisitorListQuery ,useDeleteVisitorMutation,useUpdateVisitorMutation} = visitorApi;
    