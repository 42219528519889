import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";


export const projecListtApi = createApi({
    reducerPath: "Project",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem("token")}`);
            return headers
        }
    }),
    tagTypes: ['projects'],
    endpoints: (builder) => ({
        getAllListOfProjects: builder.query<any, void>({
            query: () => ({
                url: "/project-list/",
                method: "GET",
            }),
            providesTags: ['projects']
        }),


    }),

});

export const { useGetAllListOfProjectsQuery } = projecListtApi;
