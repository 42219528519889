import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const historyApi = createApi({
  reducerPath: "history",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set("Authorization", `Token ${localStorage.getItem("token")}`);
      return headers;
    },
  }),
  tagTypes: ["booking","payment","paymentSchedule"],
  endpoints: (builder) => ({
    getBookingData: builder.query<any, void>({
      query: () => ({
        url: "/booking-summary/",
        method: "GET",
      }),
      providesTags: ["booking"],
    }),
    getPaymentList: builder.query<any, void>({
      query: () => ({
        url: "/my-payments/",
        method: "GET",
      }),
      providesTags: ["booking"],
    }),

    getPaymentScheduleList: builder.query<any, void>({
      query: () => ({
        url: "/my-payment-schedule/",
        method: "GET",
      }),
      providesTags: ["paymentSchedule"],
    }),
  }),
});

export const {
    useGetBookingDataQuery,
    useGetPaymentListQuery,
    useGetPaymentScheduleListQuery


} = historyApi
