import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const supportApi = createApi({
    reducerPath: "supportApi",
    baseQuery: fetchBaseQuery({
         baseUrl:config().BASE_URL,
         prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem("token")}`);
            return headers
        }
        }),
    tagTypes: ['support'],
    endpoints: (builder) => ({
        addSupport: builder.mutation<any,any>({
            query: (supportInfo) => ({
                url: "/customer-support/",
                method: "POST",
                body:supportInfo,
            }),
            invalidatesTags:['support']
        }),
        getTypeOfQuerys:builder.query<any, void>({
            query: () => ({
                url: "/query-type/",
                method: "GET",
            }),
            providesTags: ['support']
        }),
        getSupportList:builder.query<any,void>({
            query:()=>({
                url: "/service-request/",
                method: "GET",
            }),
            providesTags: ['support']
        }),
        getSupportTicketList:builder.query<any,void>({
            query:()=>({
                url: "/customer-support/",
                method: "GET",
                params: {
                    status: "Inprocess" 
                },
                
            }),
            providesTags: ['support']
        })
    }),
});

export const { useAddSupportMutation,useGetTypeOfQuerysQuery,useGetSupportListQuery,useGetSupportTicketListQuery} = supportApi;
