import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const fittedHomeApi = createApi({
    reducerPath: "fittedHomeApi",
    baseQuery: fetchBaseQuery({
         baseUrl:config().BASE_URL,
         prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem("token")}`);
            return headers
        }
        }),
    tagTypes: ['fittedHome'],
    endpoints: (builder) => ({
        addFittedHome: builder.mutation<any,any>({
            query: (fittedHomeInfo) => ({
                url: "/fitted-homes-request/",
                method: "POST",
                body:fittedHomeInfo,
            }),
            invalidatesTags: ['fittedHome']
        }),
    }),
});

export const { useAddFittedHomeMutation} = fittedHomeApi;
