import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const dailyHelpsApi = createApi({
    reducerPath: "dailyHelps",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem("token")}`);
            return headers
        }
    }),
    tagTypes: ['dailyHelps'],
    endpoints: (builder) => ({
        getAllDailyHelpsCategory: builder.query<any, void>({
            query: () => ({
                url: "/helpers-category/",
                method: "GET",
            }),
            providesTags: ['dailyHelps']
        }),
        getHelpersByCategory: builder.query<any, {categoryId:any}>({
            query: ({categoryId}) => ({
                url: "/helper-customer/",
                method: "GET",
                params:{
                    category:categoryId,
                    // my_requests:1
                }
            }),
            providesTags: ['dailyHelps']
        }),
        requestHelper: builder.mutation<any, {reqId:any}>({
            query: ({reqId}) => ({
                url: `/helper-customer/${reqId}/`,
                method: "PUT",
            }),
            invalidatesTags: ['dailyHelps']
        }),
        getAllListOfMyHelpers: builder.query<any, void>({
            query: () => ({
                url: "/helper-customer/?my_requests=1",
                method: "GET",
            }),
            providesTags: ['dailyHelps']
        }),
        // getAttendanceCalender: builder.query<any, { helperId: number; selectedMonth: number }>({
        //     query: ({ helperId, selectedMonth }) => ({
        //       url: `/helper-records/`,
        //       method: "GET",
        //       params: {
        //         helper_id: helperId,
        //         month: selectedMonth,
        //       },
        //     }),
        //     providesTags: ['dailyHelps'],
        //   }),
        getAttendanceCalender: builder.query<any, { helperId: number }>({
            query: ({ helperId }) => ({
              url: `/helper-records/`,
              method: "GET",
              params: {
                helper_id: helperId,
               
              },
            }),
            providesTags: ['dailyHelps'],
          }),
          addAttendance: builder.mutation<any, object>({
            query: (attendanceInfo: object) => ({
              url: "/helper-records/",
              method: "POST",
              body: attendanceInfo,
            }),
            invalidatesTags: ["dailyHelps"],
          }),
          

    }),
});

export const {useGetAllDailyHelpsCategoryQuery,useGetAllListOfMyHelpersQuery,useGetAttendanceCalenderQuery ,useGetHelpersByCategoryQuery,useRequestHelperMutation,useAddAttendanceMutation} = dailyHelpsApi;
    