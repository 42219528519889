import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export type ErrorsInterface={
    typeOfQuery: string,
    project: string,
    unitNo: string,
    mobileNo: string,
}
type InitialState = {
    errors: ErrorsInterface;
};

const initialState: InitialState = {
    errors:{
        typeOfQuery: "",
        project: "",
        unitNo: "",
        mobileNo: "",
    }
   
};
const SupportSlice = createSlice({
  name: "Support",
  initialState,
  reducers: {
    setErrors: (state: InitialState, action: PayloadAction<any>) => {
      state.errors = {...state.errors,...(action.payload)};
    },
  },
});
export default SupportSlice.reducer;
export const {setErrors } = SupportSlice.actions;
