import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const enrichServiceApi = createApi({
    reducerPath: "enrichService",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('token')}`)
            return headers
        }
    }),
    tagTypes: ['enrichService'],
    endpoints: (builder) => ({
        getAllEnrichServiceData: builder.query<any, {  service: string, searchName: string,pages:number,applyCategories: string[] }>({
            query: ({ service, searchName,pages,applyCategories}) => ({
                url: "/service-search-filter/",
                method: "GET",
                params: {
                    service: service,
                    search: searchName,
                    page: String(pages),
                    categories: applyCategories.join(','), 
                },
            }),
        }),
        getAllEnrichServiceCategory: builder.query<any, void>({
            query: () => ({
                url: "/service-category-list/",
                method: "GET",
                
            }),
        }),
        getAllServiceSubCategory: builder.query<any, void>({
            query: () => ({
                url: "/service-subcategory-list/",
                method: "GET",
                
            }),
        }),
        getServiceDatails: builder.query<any,any>({
            query: (id) => ({
                url: `/enrich-service/${id}/`,
                method: "GET",
              
            }),
            providesTags:['enrichService']
        }),
        bookServive:  builder.mutation<any, void>({
            query: (productInfo: any) => ({
                url: `/enrich-cart-products/add_service/`,
                method: "POST",
                body: productInfo
            }),
            invalidatesTags: ['enrichService']
        }),
  
    }),
});

export const {
    useGetAllEnrichServiceDataQuery,
    useGetAllEnrichServiceCategoryQuery,
    useGetAllServiceSubCategoryQuery,
    // useEnrichServiceSearchFilterListQuery,
    useGetServiceDatailsQuery,
    useBookServiveMutation,

} = enrichServiceApi;



