import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const AppointmentRequestApi = createApi({
    reducerPath: "appointment-request",
    baseQuery: fetchBaseQuery({
         baseUrl:config().BASE_URL,
         prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem("token")}`);
            return headers
        }
        }),
    tagTypes: ['appointment-request'],
    endpoints: (builder) => ({
        addAppointment: builder.mutation<any,any>({
            query: (appointmentInfo) => ({
                url: "/appointment-request/",
                method: "POST",
                body:appointmentInfo,
            }),
            invalidatesTags:['appointment-request']
        }),
        getListOfAppointment:builder.query<any,{pages:any}>({
            query:({pages})=>({
                url: "/appointment-request/",
                method: "GET",
                params:{
                    page:pages
                }
            }),
            providesTags: ['appointment-request']
        }),
        getAppointmentStatus:builder.query<any,void>({
            query:()=>({
                url: "/appointment-request/?status=102",
                method: "GET",
            }),
            providesTags: ['appointment-request']
        }),
    }),
});

export const {useAddAppointmentMutation ,useGetListOfAppointmentQuery,useGetAppointmentStatusQuery} = AppointmentRequestApi;
