import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";


export const notificationListApi = createApi({
    reducerPath: "notification",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('token')}`)
            return headers
        }
    }),
    tagTypes: ['notification'],
    endpoints: (builder) => ({
        getAllListOfNotifications: builder.query<any, { pages: number}>({
            query: ({ pages}) => ({
                url: "/notifications/",
                method: "GET",
                params: {
                    page: String(pages),
                },
            }),
            providesTags: ['notification']
        }),
        readNotification: builder.mutation<any, any>({
            query: (id) => ({
                url: `/notifications/${id}/view_notification/`,
                method: "PUT",
            }),
            invalidatesTags: ['notification']
        }),
       


    }),

});

export const {useGetAllListOfNotificationsQuery , useReadNotificationMutation} = notificationListApi;
